.text-black {
  color: black;
}
.text-default {
  color: #949494 !important;
}
.text-center {
  text-align: center !important;
}
.text-left {
  text-align: left !important;
}
.text-grey {
  color: #44546a !important;
}
.text-underline {
  text-decoration: underline;
}
.font-16px {
  font-size: 16px;
}
.heading4 {
  font-size: 16px;
  color: #44546a;
  font-weight: bold;
}
.link {
  color: #0072bc;
  text-decoration: underline;
}
