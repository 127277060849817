.legal-buttons {
  margin-top: auto;
  font-size: 12px;
  color: black;

  a{
    color: black;
  }
}
.my-sidebar {
  background: var(--bg-primary);

  .main-menu{
    height: 90dvh;
    overflow: auto;
    scrollbar-width: thin;
  }
}

.sidebar-menu-inner{
  width: auto !important;
}

.legal-buttons-sep {
  padding: 2px;
}

.root-level{
  a{
    background-color: transparent !important;
    padding: 15px !important;
  }
}

.progress-bar-black{
  background-color: #000000;
}