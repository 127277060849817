.page-content {
  margin-top: 40px;
}

.page-container {
  background-color: var(--bg-primary);
  height: auto;
  min-height: 100%;
}

@media (min-width: 767px) {
  .sidebar-menu-inner {
    display: flex;
    flex-direction: column;
    min-height: 99vh;

    .legal-pdf {
      margin-top: auto;
      padding: 10px 20px;
      font-size: 14px;
      color: white;
    }
  }

  .page-container {
    padding-left: 280px;

    &.sidebar-collapsed {
      padding-left: 65px;

      .header {
        left: 65px;
        // width: calc(100vw - 65px);
      }
    }

    .sidebar-menu {
      position: fixed;
      left: 0;
      top: 0;
      height: 100%;
    }
  }
}