.signature-step {
  max-width: 700px;
  margin: 0 auto;
  .btn {
    width: 200px;
  }
  .btn-warning {
    background: #c55a11;
  }
}

.sign-text-info {
  color: black;
  font-size: 16px;
}
