.product-selection {
  background: #eef7ff;
  border-radius: 5px;
  padding: 5px;
  > div {
    padding: 10px !important;
    margin-bottom: 52px;
    cursor: pointer;
    &:hover .product {
      box-shadow: 5px 5px 5px 0px rgba(32, 119, 197, 0.2);
    }
  }
  .product {
    position: relative;
    padding: 10px;
    border-radius: 5px;
    background: white;
    height: 100%;
  }
  h4 {
    color: #2077c5;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }
  .price {
    text-align: center;
    strong {
      color: black;
      font-size: 20px;
      font-weight: bold;
    }
  }
  .category {
    background: #ecf6ff;
    border: 1px solid #7ab9e4;
    color: #7ab9e4;
    padding: 5px;
    text-align: center;
    border-radius: 5px;
    margin: 10px 0;
  }
  .btn-document-link {
    position: absolute;
    right: 7px;
    top: 14px;
  }
}
