.news-list {
  padding-left: 0;
  list-style: none;
  li {
    padding: 10px 20px 10px 30px;
    color: black;
    position: relative;
    &:before {
      content: '•';
      position: absolute;
      left: 10px;
      top: 10px;
    }
  }
  li:nth-child(odd) {
    background: #e1e1e1;
  }
  li:nth-child(even) {
    background: #f0f0f0;
  }
}
