.benefit-price, .benefit-discount, .benefit-price-total, .benefit-prima-anual {
  padding: 0 15px;
  display: inline-block;
  border-radius: 10px;
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  vertical-align: middle;
  text-align: center !important;
}

.benefit-prima-anual {
  border: 1px solid #d5d0d0;
  color: #999;

}

.benefit-price {
  border: 1px solid #000;
  color: #000;
}

.benefit-discount {
  border: 1px solid #8abd88;
  color: #8abd88;
}

.benefit-price-total {
  border: 1px solid #000;
  color: #000;
}


