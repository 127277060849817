.logo h1 {
  color: white;
  font-weight: bold;
}

.login-screen {
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  justify-content: center;
  background-size: cover;

  @media (min-width: 768px) {
    margin-left: -280px;
  }
}

.login-screen-logo{
  position: absolute;
  top:30px;
  left:30px;
}

.legal-button,
.legal-button-login {
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    width: 0;
    height: 1px;
    background: var(--text-primary);
    transform: translate(-50%, 0);
    transition: width 0.3s;
  }

  &:hover {
    &::before {
      width: 100%;
    }
  }
}

.cookie-consent-more-button {
  background: rgb(255, 255, 255);
  border: 0px;
  border-radius: 0px;
  box-shadow: none;
  color: black;
  cursor: pointer;
  flex: 0 0 auto;
  padding: 5px 10px;
  margin-top: 3px;
}

.login-form {
  width: 300px;
  font-size: 16px;
  padding: 20px 20px 10px 20px;
  border-radius: 35px;
  background-color: white;
  position: relative;
  margin-bottom: -50px;

  @media (min-width: 768px) {
    width: 340px;
  }

  input {
    text-align: center !important;
    background: var(--bg-primary) !important;
    border: 1px solid var(--bg-primary) !important;
    border-radius: 10px;
    padding: 20px 10px;
    font-size: 16px;
    color: white;

    &::placeholder {
      color: white;
      opacity: 0.8;
    }

    &:-ms-input-placeholder {
      color: white;
      opacity: 0.8;
    }

    &::-ms-input-placeholder {
      color: white;
      opacity: 0.8;
    }
  }

  .btn {
    font-size: 16px;
    padding: 5px 30px;
    border-radius: 10px;
    color: black;
  }

  .forgot-password {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 10px);
    min-width: max-content;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      width: 0;
      height: 1px;
      background: var(--text-primary);
      transform: translate(-50%, 0);
      transition: width 0.3s;
    }

    &:hover {
      &::before {
        width: 100%;
      }
    }
  }
}