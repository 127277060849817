
.loginBottomNavbar {
  align-items: baseline;
  color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 999;
  bottom: 0;
}

.loginBottomNavbar a{
  color: white;
}

.loginBottomNavbarInner {
  flex: 1 0 300px; margin: 15px;
}
