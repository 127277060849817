.bottom-bar-button-left {
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: relative;
  color: white !important;

  a {
    color: white !important;
  }

  &::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    width: 0;
    height: 1px;
    background: var(--text-primary);
    transform: translate(-50%, 0);
    transition: width 0.3s;
  }

  &:hover {
    &::before {
      width: 100%;
    }
  }
}