.food-voucher-row {
  display: flex;
  align-items: center;
  label {
    width: 50px;
  }
  .row {
    flex: 1;
    align-items: center;
  }
  .total {
    width: 70px;
    text-align: center;
  }
}
