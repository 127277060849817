.company-selection {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  .provider {
    margin: 0 20px 20px;
    &:hover .provider-info {
      border-color: #ebebeb;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.04);
    }
  }
  .provider-info {
    font-size: 24px;
    font-weight: bold;
    color: #0072bc;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 5px;
    img {
      max-height: 100%;
    }
  }
}
