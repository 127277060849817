.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: .25rem;
  margin-bottom: 20px;
  font-size: 14px;
}
.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: .75rem;
}

.card-header:first-child {
  border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
  font-size: 14px;
  font-weight: bold;
}
.card-header {
  padding: .75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0,0,0,.03);
  border-bottom: 1px solid rgba(0,0,0,.125);
}

.total-benefits {
  border-top: 1px solid;
}

.label-ss {
  background: #8497B0 !important;
}
.label-fixed-salary{
  background: #44546A !important;
}
.label-variable-salary{
  background: #9DC3E6 !important;
}
.label-benefits{
  background: #B4C7E7 !important;
}


.chart {
  max-width: 400px;
  flex: 0 0 400px;
  -ms-flex: 0 0 16.666667%
}

