
.bordered {
 border: 1px solid;
}

.panel-heading {
  padding: 5px 10px;
  font-size: 13px;
  font-weight: bold;
}
.panel.panel-grey {
  > .panel-heading {
    background: #bfbfbf;
    text-align: center;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    text-align: center;
    float: none;
  }
}
.panel.panel-dark-blue {
  > .panel-heading {
    background: #44546a;
    color: white;
    border-radius: 0px;
    font-weight: normal;
  }
}
