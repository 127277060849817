.children-row {
  padding-left: 10px;
}
.child-info {
  font-size: 16px;
  color: #44546a;
  font-weight: bold;
  margin-left: -10px;
  margin-bottom: 10px;
}
