$shadowVectorBig: -2px 5px 30px;
$shadowVectorShort: -2px 5px 10px;
$shadowColorWhite: rgba(255, 255, 255, 0.5);
$shadowColorBlack: rgba(0, 0, 0, 0.5);
$transition: 0.3s;

.menu-item {
  width: 280px;
  height: 450px;
  font-size: 14px;
  font-weight: bold;
  padding: 30px 20px 20px 20px;
  border-radius: 15px;
  background-size: 120% 120%;
  background-repeat: no-repeat;
  background-position: center;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  transition: $transition;
  border: 1px solid rgba(255, 255, 255, 0);

  @media (min-width: 768px) {
    width: 190px;
    height: 286px;
  }

  &:hover {
    z-index: 2;
    transform: scale(1.05);
    background-size: 102% 102% !important;
    box-shadow: $shadowVectorBig $shadowColorWhite,
                inset 0 0 5px 0 rgba(0, 0, 0, 0.5),
                inset 0 0 10px 0 rgba(255, 255, 255, 0.2);
    filter: contrast(1.3);
    border: 1px solid rgba(255, 255, 255, 0.2);

    .menu-item-icon {
      transform: scale(1.3);
      filter: drop-shadow($shadowVectorShort $shadowColorBlack);
    }

    .menu-item-text {
      transform: scale(1.1);
      text-shadow: $shadowVectorShort $shadowColorBlack;
    }
  }

  &-icon {
    transition: $transition;
  }

  &-text {
    display: flex;
    flex-direction: column;
    transition: $transition;

    &-number {
      font-size: 11px;
    }

    &-title {
      font-size: 20px;
      line-height: 0.9;
    }
  }
}

.my-exec-button {
  width: 60px;
}

.my-label {
  margin: 0px;
  font-size: 12px;
}