.grey-bar {
  background: #bfbfbf;
  color: white;
  border-radius: 7px;
  text-align: center;
  padding: 2px 5px;
}
.bag-box {
  border: 1px solid #bfbfbf;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  margin-top: 5px;
  min-height: 80px;
  .progress {
    height: 10px;
  }
}
