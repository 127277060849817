.page-container .sidebar-menu .logo-env {
  padding: 15px !important;
  display: flex;
  align-items: center;

  a,
  .logo {
    position: relative;
    max-height: 40px;

    img {
      max-width: 100%;
      max-height: 40px;
    }
  }

  .sidebar-collapse {
    margin-left: auto;
  }
}

.dropdown-menu-help {
  width: 250px !important;
}

.amount-highlight {
  font-size: 18px;
  line-height: 0.8;
  font-weight: 600;
}

.amount-title {
  font-size: 11px;
  font-weight: 100;
  text-transform: uppercase;
}

.header-warning {
  background: #ffd5d5;
  color: black;
}

.container-amounts {
  color: grey;
  border: 2px solid grey;
  padding: 13px 7px 7px 7px;
  border-radius: 5px;
}

.cart.dropdown {
  .badge {
    top: 0 !important;
    right: 0 !important;
  }

  .dropdown-menu {
    left: auto;
    right: 5px;
    width: 310px;
  }

  .cost {
    color: #373e4a;
  }

  .cart-item {
    padding: 10px 20px;
    display: flex !important;
    align-items: center;
  }

  .checkout-item {
    padding: 10px 20px !important;
    background: white;

    a {
      color: #ec5956;

      &:hover {
        background: none;
        text-decoration: underline;
      }
    }
  }

  &:hover {
    .dropdown-menu {
      display: block;
    }

    >a {
      background-color: #f5f5f6;
      border-radius: 3px 3px 0 0;
      background-clip: padding-box;
    }
  }
}

.header {
  border-bottom: 1px solid #eeeeee;
  z-index: 30;
  background: white;
  padding-bottom: 20px;
}

.bag-status {
  color: #2f5597;
  font-size: 16px;

  strong {
    font-size: 1.3em;
  }
}