.card {
  border-radius: 0 !important;

  &-header {
    background-color: var(--bg-primary);
    padding: 7px !important;
    color: var(--text-primary);

    h3 {
      font-size: 16px !important;
      margin: 0;
    }
  }
}