.panel-simulator {
  font-size: 12px;
  border: 1px solid #ebebeb;
  border-radius: 0;
  .panel-heading {
    background-color: #f0f0f1;
    color: #373e4a;
    border-bottom: 1px solid #ebebeb;
  }
  .panel-body {
    padding: 0;
  }
  label {
    margin-bottom: 0;
  }
  .highlighted {
    color: black;
  }
  div {
    padding: 10px;
  }
}
.calc-total {
  text-align: center;
  background: #bfbfbf;
  color: white;
  display: inline-block;
  padding: 5px 20px;
  border-radius: 7px;
}
