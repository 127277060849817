.user-picture {
  img {
    width: 100px;
  }
}
.profile-picture {
  img {
    border-radius: 50%;
    display: block;
    height: auto;
  }
}
