.product-container {
  display: flex;
  flex-wrap: wrap;
  .product-col {
    width: 650px;
  }
  .calc-col {
    width: 480px;
    margin-top: 100px;
    margin-left: 50px;
  }

}
@media (max-width: 768px){
  .product-container {
    .product-col {
      width: 100%;
    }
  }
}
@media (max-width: 1320px) {
  .product-container {
    .calc-col {
      margin: 40px 0;
    }
  }
}
