.product-status, .product-status-mobile {
  margin: 5px 5px;
  text-align: center;
  font-size: 12px;
  .name {
    font-size: 13px;
    font-weight: bold;
    color: #797979;
  }

  &-icon{
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 50px;
      max-height: 50px;
    }
  }

  .status {
    padding: 5px;
    width: 100px;
    height: 50px;
    color: white;
    background: #d9d9d9;
    border-radius: 10px;
    margin-bottom: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &.contracted {
      background: #afe986;
    }
    &.missing-document {
      background: #ffd966;
    }
    &.pending-review {
      background: #4a586b;
    }
    &.product-cancelled {
      background: #8f9297;
    }

    &.pending-completion {
      background: #f8cbad;
    }


  }
}
.product-status-mobile {
  margin: 5px 5px;
  text-align: center;
  .name {
    font-size: 13px;
  }
  img {
    width: 80px;
  }
  .status {
    padding: 5px;
    width: 80px;
    height: 50px;
  }
}

label {
  &.contracted {
    background: #c6e0b4;
  }
  &.missing-document {
    background: #ffd966;
  }
  &.pending-review {
    background: #4a586b;
  }
  &.pending-completion {
    background: #f8cbad;
  }
}
