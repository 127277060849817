table.table-dark {
  th,
  td {
    vertical-align: middle;
  }
  thead th {
    background: #a5a5a5;
    color: white;
    font-size: 14px;
  }
  tbody {
    color: black;
    tr:nth-child(odd) td {
      background: #e1e1e1;
    }
    tr:nth-child(even) td {
      background: #f0f0f0;
    }
  }
  .label {
    color: black;
    font-size: 10px;
  }
}
