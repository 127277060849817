.cart-detail {
  .table-cart {
    vertical-align: middle;
    th {
      padding: 15px 10px;
      color: #1b1919;
      border-top: 2px solid #efefef !important;
      border-bottom: 2px solid #efefef;
      &:first-child {
        text-align: center;
      }
    }
    td {
      padding: 5px 10px;
      border-bottom: 1px solid #e6e6e6;
      vertical-align: middle !important;
      font-size: 12px;
    }
  }
  .cart-title {
    font-size: 16px;
    font-weight: bold;
    // padding: 10px 20px;
  }

  .total {
    color: black;
    font-size: 16px;
    font-weight: bold;
  }
  .bag {
    font-style: italic;
    color: #7f7f7f;
  }
  .img-benefit {
    width: 100px;
  }
  td.col-action {
    font-size: 15px;
  }
  .btn-icon {
    width: 110px;
  }
}
.cart-edit-modal {
  .modal-header {
    border-bottom: 0px;
    height: 0;
  }
  .modal-body {
    padding-top: 0;
  }
  .modal-footer {
    border-top: 0;
  }
  .panel-heading {
    background: transparent;
    border: 0;
  }
}

@media (max-width: 768px) {
  .cart-detail {
    .table-cart {
      th {
        padding: 15px 4px;
      }

      td {
        padding: 15px 4px;
      }
    }
    .btn-icon {
      width: 93px;
    }
    .benefit-name{
      font-size: 12px;
    }
  }
}