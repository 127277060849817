.user-documents {
  label.status {
    background: #c6e0b4;
    color: white;
    padding: 5px 10px;
    &.pending-review {
      background: #4a586b;
    }
  }
  .btn-detail {
    font-size: 16px;
    cursor: pointer;
  }
}
