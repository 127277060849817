.custom-checkbox {
  margin-top: 3px;
}
.custom-checkbox input[type='checkbox'] {
  opacity: 0;
  position: absolute;
}

.custom-checkbox label {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
}

.custom-checkbox label::before,
.custom-checkbox label::after {
  position: absolute;
  content: '';
  display: inline-block;
}

/*Outer box of the fake custom-checkbox*/
.custom-checkbox label::before {
  height: 16px;
  width: 16px;
  left: 0px;
  top: 0.15em;
  border: 1px solid #949494;
}

/*Checkmark of the fake custom-checkbox*/
.custom-checkbox label::after {
  height: 5px;
  width: 10px;
  border-left: 2px solid #0e76a8;
  border-bottom: 2px solid #0e76a8;
  transform: rotate(-45deg);
  left: 3px;
  top: 0.4em;
}

/*Hide the checkmark by default*/
.custom-checkbox input[type='checkbox'] + label::after {
  content: none;
}

/*Unhide on the checked state*/
.custom-checkbox input[type='checkbox']:checked + label::after {
  content: '';
}

.custom-control-input:disabled ~ .custom-control-label {
  opacity: 0.7;
  &:before {
    border-color: #eeeeee;
  }
}

.input-group.embed {
  position: relative;
  .input-group-addon {
    position: absolute;
    right: 1px;
    top: 1px;
    width: 20px;
    height: 30px;
    line-height: 30px;
    z-index: 2;
    background: transparent;
    border: 0;
    padding: 0px;
  }
}

label.required {
  position: relative;
  padding-right: 15px;
  &:after {
    position: absolute;
    content: '*';
    right: 5px;
    color: #d42020;
    margin-left: 5px;
  }
}
.form-wizard > ul {
  display: flex;
  width: 60%;
  margin: 0 auto;
  li {
    width: auto;
    text-align: right;
    position: relative;
    display: block;
    &:not(:first-child) {
      flex: 1;
      &:before {
        content: '';
        position: absolute;
        left: -37px;
        top: -10px;
        right: 50px;
        border-top: 10px solid #ebebeb;
        height: 1px;
      }
    }
    &.completed:before {
      border-top-color: #2f5597;
    }

    a {
      display: inline-block;
      text-align: center;
      position: relative;
      z-index: 5;
    }
  }
  li.completed {
    a {
      color: #2f5597;
      span {
        background: #2f5597;
        color: #fff;
        box-shadow: 0px 0px 0px 5px #2f5597;
      }
    }
  }
}
