.contract-body {
  max-width: 500px;
  background-color: #eaefee;
  padding-top: 40px;
  padding-bottom: 10px;
  border-radius:5px;
}

.contract-body-alt {
  background-color: #eaefee;

  border: 1px solid #bfbfbf;
  border-radius: 10px;
  padding: 10px;
  margin-top: 5px;
}