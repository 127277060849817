.preview {
  display: inline-block;
  overflow: hidden;
}
.previewMD {
  width: 120px;
}
.previewSM {
  width: 44px;
}
.uploaded {
  display: inline-block;
  position: relative;
  text-align: center;
  overflow: hidden;
  .tools {
    display: none;
    position: absolute;
    top: 40%;
    width: 100%;
    z-index: 2;
  }
  &:after {
    content: '';
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.2);
    z-index: i1;
  }
  &:hover {
    &:after {
      display: block;
    }
    .tools {
      display: block;
    }
  }
}
.tools {
  span {
    font-size: 18px;
  }
}
.rounded-circle {
  overflow: hidden;
}
