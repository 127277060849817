.menu-top {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.cookies-body {
  font-size: 12px !important;
}

.cookies-title {
  font-size: 20px !important;
  font-weight: bold !important;
}

.menu-paragraph {
  max-width: 395px;
  font-size: 11px;
  color: white;
  font-weight: 100;
}

.menu-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.center {
  margin: auto;
  width: 50%;
  padding: 10px;
}

.menu-page-container {
  /* Full height */
  height: 100%;
  min-height: 100vh;
  padding: 90px 35px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 40px;
  
  @media (min-width: 768px) {
    margin-left: -280px;
  }
}