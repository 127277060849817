@import '~rc-slider/assets/index.css';
@import 'size';
@import 'modal';
@import 'form';
@import 'paragraph';
@import 'product';
@import 'button';
@import 'dropzone';
@import 'toast';
@import 'panel';
@import 'spinner';
@import 'table';
@import 'card';
@import 'plugins/icons/materialdesignicons';

.mw-1000px {
  max-width: 1024px !important;
}

.cp {
  cursor: pointer;
}

.links-list {
  padding: 0;
  margin: 0;
}

.scrollme-x {
  overflow-x: scroll;
}

.page-title {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  img {
    height: 40px;
    margin-right: 15px;
  }

  h1 {
    margin: 0;
  }
}

a {
  color: #5c5c5c;
}

.text-danger {
  color: #ff0500
}

:root {
  --bg-primary: #000000;
  --text-primary: #ffffff;
}

.rc-slider-track{
  background-color: #000000;
}

.rc-slider-handle,
.rc-slider-handle:focus,
.rc-slider-handle-click-focused{
  border: 2px solid #000000;
}

.rc-slider-handle:focus{
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}