.btn-help {
  width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 100%;
  vertical-align: middle;
  margin-left: 5px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  padding: 0;
  line-height: 20px; }

.embed-img {
  background-repeat: no-repeat;
  background-size: cover; }

.featured-image {
  margin-bottom: 10px;
  height: 120px; }

.logo-small {
  width: 50px; }

.image-card {
  display: flex; }
  .image-card .image {
    width: 100px;
    flex-shrink: 0;
    margin-right: 20px;
    display: block; }
    .image-card .image img {
      width: 100%; }
  .image-card .desc {
    white-space: pre-line; }
  .image-card .card-detail {
    flex: 1; }

.modal-body .image-card {
  align-items: center; }
  .modal-body .image-card .image {
    width: 50px; }

.card-benefit {
  width: max-content; }

.provider-website {
  text-align: center; }
  .provider-website a {
    font-size: 14px;
    color: #21a9e1;
    text-decoration: underline; }

[data-f-id] {
  display: none; }

.month-row [type='number'],
.input-group.embed [type='number'] {
  -moz-appearance: textfield; }
  .month-row [type='number']::-webkit-outer-spin-button, .month-row [type='number']::-webkit-inner-spin-button,
  .input-group.embed [type='number']::-webkit-outer-spin-button,
  .input-group.embed [type='number']::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
    /* <-- Apparently some margin are still there even though it's hidden */ }
