.home-page {
  .stats {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
    width: 100%;
    margin-bottom: 40px;
  }

  .stats-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;

    @media (min-width: 1375px) {
      border-right: 1px solid #f0f0f0;
      justify-content: center;

      &:first-of-type {
        justify-content: flex-start;
      }

      &:last-of-type {
        border-right: none;
        justify-content: flex-end;
      }
    }
  }

  .stats-num {
    font-size: large;
    color: #7f7f7f;
  }

  .stats-wrapper-mobile {
    border: 1px solid #f0f0f0;
    margin: 1px;
  }

  .stats-label-mobile {
    padding: 6px 5px 5px 5px;
    color: #7f7f7f;
  }

  .bordered {
    border: 1px solid #ddd;
  }

  .tile-stats {
    display: flex;
    align-items: center;
    gap: 8px;
    background: transparent;
    padding: 5px;
    margin: 0;

    img {
      @media (max-width: 530px) {
        height: 20px;
      }
    }

    .num {
      color: #7f7f7f;
      font-weight: bold;
      font-size: 30px;
      line-height: 30px;
      min-width: max-content;

      @media (max-width: 530px) {
        font-size: 16px;
      }
    }

    h3 {
      font-size: 13px;
      font-weight: 600;
      text-transform: uppercase;
      color: #7f7f7f;
      max-width: 110px;
      margin: 0;

      @media (max-width: 530px) {
        font-size: 11px;
      }
    }
  }

  .products-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 750px;
    margin: 0 auto;
  }
}

@media (max-width: 1375px) {
  .home-page {
    .stats {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@media (max-width: 1000px) {
  .home-page {
    .stats {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media (max-width: 530px) {
  .home-page {
    .stats {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
}