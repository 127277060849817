.fade.show {
  opacity: 1;
}
.modal-backdrop.show {
  opacity: 0.5;
}
.modal-dialog {
  margin-top: 50px;
  transform: none !important;
  .modal-title {
    font-size: 15px;
    float: left;
  }
}
.modal-body {
  max-height: 70vh;
  overflow-y: auto;
  width: 100%;
}
@media (min-width: 768px) {
  .modal-dialog {
    width: 650px !important;
    margin: 30px auto;
  }
}
