.cb-navbar-content {
  background: color-mix(in srgb, var(--bg-primary) 100%, transparent 50%);
  backdrop-filter: blur(10px);
  border: none;
  padding: 15px;
}

.menu-language-button {
  height: 50px;
}

.languages-mobile {
  list-style: none;
  padding-left: 0px;
  float: right;
}

.navbar-brand {
  max-width: 180px;
}

.button-cart {
  padding: 4px;
  background: white;
}

.button-help {
  padding: 4px;
}

.shop-cart-mobile {
  font-size: large;
}

.help-mobile {
  font-size: large;
}

.logout-mobile {
  font-size: large;
}

.logout-mobile-button {
  position: relative;
  float: right;
  margin-right: 15px;
  padding: 4px 6px;
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: transparent;
  background-image: none;
  border: 1px solid grey;
  border-radius: 3px;
}

.button-remove-item {
  padding: 4px;
}

.minus-circled-big {
  font-size: large;
}

.cart-item {
  padding: 5px 10px;
  display: flex !important;
  align-items: center;
}

.checkout-item {
  padding: 10px 10px !important;
  background: white;

  a {
    color: #ec5956;

    &:hover {
      background: none;
      text-decoration: underline;
    }
  }
}