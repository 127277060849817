.btn {
  .btn-icon {
    height: 20px;
    vertical-align: middle;
    margin-right: 5px;
  }
}
.btn-link {
  color: #5c5c5c;
  cursor: pointer;
  text-decoration: underline;
}
.btn-none {
  background: none;
  border: none;
  &:focus {
    outline: none;
  }
}
